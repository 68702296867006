import React from 'react'
import { graphql } from 'gatsby'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import Layout from '../components/layout'
import config from '../../../config.json'

export const query = graphql`
  query homePageFormsQuery {
    allSanityForms (
      sort: { fields: [order], order: ASC }
      filter: { enabled: { eq: true } }
    ) {
      nodes {
        name
        form
        ringColor
        rotateColor
        logo: _rawLogo
      }
    }
  }
`

export default function HomePage({ data }) {
  const { nodes } = data?.allSanityForms

  return (
    <Layout>
      <SEO title={`Gift with Purcahse`}/>
      <h1>Escalade Sports Gift with Purchase Forms</h1>
      <div style={{width: '100%', height: '1rem'}}/>
      <div css={styles}>
        {nodes.map(({ name, form, ringColor:ring, rotateColor:rotate, logo }) => {
          return (
            <Box href={`/${name}`} ring={ring} rotate={rotate}>
              {logo ?
              <img src={getFluidGatsbyImage(
                logo?.asset?._ref,
                { maxWidth: 5000, quality: 100 },
                config).src}
              />
              :
              <p>{name}:&nbsp;{form}</p>}
            </Box>
          )
        })}
      </div>
    </Layout>
  )
}

const styles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  max-width: 1024px;
  margin: 0 auto;
`

const Box = styled.a(({ ring, rotate }) => {
  return css`
    border: 2px solid ${rotate ?? ring ?? 'white'};
    border-radius: 10px;
    width: 300px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    img {
      padding: 1rem;
      max-width: 100%;
      max-height: 100%;
    }
    p {
      font-size: 0.75rem;
    }
  `
})